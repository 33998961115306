import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'
import { SWRConfig } from 'swr'
import App from './App'
import { store } from './store'
import reportWebVitals from './reportWebVitals'
//@Styles
// import 'react-multi-carousel/lib/styles.css'
// import 'react-lazy-load-image-component/src/effects/blur.css'
import 'aos/dist/aos.css'
// import 'simplebar/src/simplebar.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import './index.css'
import APIService from './services'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <SWRConfig
        value={{
          // refreshInterval: 3000,
          revalidateOnFocus: false,
          revalidateOnReconnect: true,
          fetcher: (url) => APIService.fetcher(url),
        }}
      >
        <App />
      </SWRConfig>
    </Provider>
  </HelmetProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
