import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { CustomImage } from '..'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'

const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: '100%',
      height: 400,
    },
    container: {
      textAlign: 'center',
    },
    text: {
      lineHeight: '1.3 !important',
    },
  })
)

function ContactList(props) {
  const classes = useStyles()
  const { image, title, contacts } = props
  return (
    <div>
      <Grid container alignItems='center' className={classes.container}>
        <Grid data-aos={'fade-right'} item xs={12} sm={8}>
          <CustomImage lazy src={image} alt='...' className={classes.image} />
        </Grid>
        <Grid data-aos={'fade-left'} item xs={12} sm={4}>
          <List>
            {contacts?.map((contact, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={
                    <Typography
                      variant='h6'
                      color='primary'
                      className={classes.text}
                    >
                      {contact?.value}
                    </Typography>
                  }
                  secondary={contact?.name}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactList
