import { useState } from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import validate from 'validate.js'
import CustomOutlineTextField from './CustomOutlineTextField'
import toast, { Toaster } from 'react-hot-toast'
import APIService from '../../services'
import { useEffect } from 'react'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
)

const schema = {
  emailAddress: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 6,
      message: 'must be at least 6 characters',
    },
  },
}
function LoginForm({ mutate, history }) {
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState((prevData) => ({
      ...prevData,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false

  const handleChange = (event) => {
    event.persist()

    setFormState((prevData) => ({
      ...prevData,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (formState.isValid) {
      setLoading(true)
      const response = APIService.post('/admin/login', formState.values)
      toast.promise(
        response,
        {
          loading: 'Loading',
          success: (res) => {
            localStorage.setItem('accessToken', res?.data?.accessToken)
            localStorage.setItem('refreshToken', res?.data?.refreshToken)
            mutate()
            setLoading(false)
            console.log('history', history)
            history.push('/admin/overview')
            return 'Login successful!'
          },
          error: (err) => {
            setLoading(false)
            return (
              err?.response?.data?.message ||
              err?.message ||
              'Something went wrong, try again.'
            )
          },
        },
        {
          error: {
            duration: 6000,
          },
        }
      )
    }

    setFormState((prevData) => ({
      ...prevData,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  return (
    <form
      name='password-reset-form'
      method='post'
      onSubmit={handleSubmit}
      className={classes.root}
    >
      <CustomOutlineTextField
        placeholder='Email Address'
        label='Email Address *'
        variant='outlined'
        size='small'
        name='emailAddress'
        fullWidth
        helperText={
          hasError('emailAddress') ? formState.errors.emailAddress[0] : null
        }
        error={hasError('emailAddress')}
        onChange={handleChange}
        value={formState.values.emailAddress || ''}
      />
      <CustomOutlineTextField
        placeholder='Password'
        label='Password *'
        variant='outlined'
        size='small'
        name='password'
        fullWidth
        helperText={hasError('password') ? formState.errors.password[0] : null}
        error={hasError('password')}
        onChange={handleChange}
        type='password'
        value={formState.values.password || ''}
      />
      <LoadingButton
        size='medium'
        variant='contained'
        type='submit'
        color='secondary'
        loading={loading}
        fullWidth
      >
        Login
      </LoadingButton>
      <Toaster />
    </form>
  )
}

export default LoginForm
