import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@mui/styles'
import Box from '@mui/material/Box'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}))

/**
 * Component to display the alternative sections
 *
 * @param {Object} props
 */
const SectionFullWidth = (props) => {
  const theme = useTheme()
  const {
    children,
    backgroundColor,
    dark = true,
    padding = true,
    className,
    withBg = false,
    alternate,
    ...rest
  } = props

  const classes = useStyles()

  // [classes.dark]: dark,
  // [classes.light]: !dark,
  // [classes.background]: withBg,

  return (
    <Box
      component='section'
      className={classes.root}
      sx={{
        background: withBg
          ? `url(/images/background.jpg), ${theme.palette.primary.main}`
          : dark
          ? backgroundColor
            ? backgroundColor
            : theme.palette.primary.main
          : 'white',
      }}
      {...rest}
    >
      <Box component='div' sx={{ py: padding ? 10 : 0 }}>
        {children}
      </Box>
    </Box>
  )
}

SectionFullWidth.defaultProps = {
  dark: true,
  padding: true,
  withBg: false,
  alternate: false,
}

SectionFullWidth.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should be dark
   */
  dark: PropTypes.bool,
  withBg: PropTypes.bool,

  padding: PropTypes.bool,

  alternate: PropTypes.bool,
}

export default SectionFullWidth
