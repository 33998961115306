import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import { auth, pages, contacts, team, content } from './reducer'

const reducer = combineReducers({
  auth,
  pages,
  contacts,
  team,
  content,
})

export const store = configureStore({
  reducer,
})
