import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Image from '../Image'
import { useTheme, makeStyles, createStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      textAlign: 'center',
    },
    icon: {
      width: '100%',
      height: 80,
      marginBottom: 10,
      marginTop: 10,
      [theme.breakpoints.down('sm')]: {
        height: 40,
      },
    },
    caption: {
      color: 'white',
      fontWeight: 'bolder !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px !important',
      },
    },
  })
)

function FeatureCard({ item, className, active, index }) {
  const classes = useStyles()
  const theme = useTheme()
  return (
    <Card
      sx={{
        // minWidth: 275,
        transition: 'background-color 300ms ease-in',
        borderRadius: 8,
        backgroundColor:
          active === index
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
      }}
      className={className}
    >
      <CardContent className={classes.content}>
        <Image src={item?.icon} className={classes.icon} />
        <Typography sx={{ mb: 1.5 }} className={classes.caption}>
          {item?.caption}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default FeatureCard
