import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Header, Section } from "../components";
import CustomHtml from "../components/html";

function Terms() {
  const theme = useTheme();
  const { terms } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={terms?.header?.title}
        overline={terms?.header?.overline}
        image={terms?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <CustomHtml html={terms.main.data} theme={theme} />
      </Section>
    </div>
  );
}

export default Terms;
