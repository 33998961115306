// Import Swiper React components
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { createStyles, makeStyles } from "@mui/styles"
import { Link as RouterLink } from 'react-router-dom'
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
// import required modules
// Import Swiper styles
import "swiper/swiper-bundle.css";
import "./style.css";
// import CustomImage from "../Image";

const useStyles = makeStyles((theme) => createStyles({
  root: {
    height: 600,
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      height: 700,
    }
  },
  blurry: {
    width: 800,
    height: 800,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    },
    margin: "auto",
    borderRadius: "50% 22% 40% 80%",
    filter: "blur(100px)",
    background: `radial-gradient(circle at 50% 50%,${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    opacity: 0.2,
    position: "absolute",
    top: 0
  },
  swiper: {
    backgroundPosition: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: "top left",
    }
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: "bottom",
    [theme.breakpoints.down('sm')]: {
      width: '80% !important',
      // height: 150,
    },
  },
  content: {
    wordWrap: 'break-word',
    textAlign: 'end',
    [theme.breakpoints.down('sm')]: {
      textAlign: "center"
    }
  },
  title: {
    fontWeight: '700 !important',
    fontSize: '4rem !important',
    textTransform: 'capitalize',
    textAlign: 'end',
    lineHeight: 1,
    // color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.2rem !important',
      textAlign: "center"
    },
  },
  body: {
    fontSize: '1.5rem !important',
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem !important',
    },
  },
  height: {
    height: "100%",
    alignItems: "center"
  }
}))

function Hero({ data }) {
  const classes = useStyles()

  return (
    <div className={`${classes.root}`}>
      <Swiper
        loop
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Autoplay, Pagination]}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index.toString()}
            style={{
              height: "100%",
              backgroundImage: `url(${item?.featureImage})`,
            }}
            className={classes.swiper}
          >
            <Container maxWidth="lg" className={classes.height}>
              <Grid container spacing={3} justifyContent="center" className={classes.height}>
                <Grid item xs={12} sm={6} sx={{ height: { xs: 250, sm: "auto" } }}></Grid>
                <Grid item xs={12} sm={6} className={classes.content}>
                  <Typography
                    variant='h4'
                    component='h1'
                    className={classes.title}
                    gutterBottom
                  >
                    {item?.title}
                  </Typography>
                  {Array.isArray(item?.body) ? (
                    item?.body.map((text, i) => (
                      <Typography key={i}>
                        {text}
                      </Typography>
                    ))
                  ) : (
                    <Typography paragraph gutterBottom>
                      {item?.body}
                    </Typography>
                  )}

                  {item?.buttonText && (
                    <Button
                      variant='contained'
                      color='primary'
                      size='large'
                      component={RouterLink}
                      to={item?.buttonLink}
                      endIcon={<ArrowRightIcon />}
                    >
                      {item?.buttonText}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={classes.blurry} />
    </div>
  )
}

export default Hero