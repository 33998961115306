import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { ContactList, Header, Section } from '../components'
import { ContactForm } from '../components/form'

function Contact() {
  const { contact } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={contact?.header?.title}
        overline={contact?.header?.overline}
        image={contact?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <ContactList
          contacts={contact?.main?.contact}
          image={contact?.main?.featuredImage}
        />
      </Section>
      <Section alternate narrow>
        <Box sx={{ p: 2 }}>
          <Typography variant='h6' color='primary'>
            Leave us a message
          </Typography>
          <ContactForm />
        </Box>
      </Section>
    </div>
  )
}

export default Contact
