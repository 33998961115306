import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom'
import { AuthLayout, PublicLayout } from '../layout'
import {
  AboutView,
  ContactView,
  HomeView,
  NotFoundView,
  JoinView,
  SupplierView,
  CookiePolicy,
  PrivacyPolicy,
  Terms,
} from '../pages'
import {
  AdminDashboard,
  AdminLogin,
  AdminPages,
  AdminProfile,
} from '../pages/admin'
import PrivateRoute from './PrivateRoute'
import RouteWithLayout from './RouteWithLayout'

const DashboardComponent = ({ isAuth, ...props }) => {
  let { path } = useRouteMatch()

  // console.log(path)

  return (
    <Switch>
      {!isAuth ? (
        <Route path={path} exact>
          <AuthLayout>
            <AdminLogin {...props} />
          </AuthLayout>
        </Route>
      ) : (
        <Redirect to='/admin/overview' />
      )}
      <PrivateRoute exact path={`${path}/overview`} {...props}>
        <AdminDashboard {...props} />
      </PrivateRoute>
      <PrivateRoute {...props} path={`${path}/pages`}>
        <AdminPages {...props} />
      </PrivateRoute>
      <PrivateRoute {...props} path={`${path}/profile`}>
        <AdminProfile {...props} />
      </PrivateRoute>
    </Switch>
  )
}

function Routes({ authMutate, ...props }) {
  return (
    <Switch>
      <RouteWithLayout
        path='/'
        component={HomeView}
        layout={PublicLayout}
        exact
        {...props}
      />
      <RouteWithLayout
        path='/about-us'
        component={AboutView}
        layout={PublicLayout}
        exact
        {...props}
      />
      <RouteWithLayout
        path='/contact-us'
        component={ContactView}
        layout={PublicLayout}
        exact
        {...props}
      />
      {/* <RouteWithLayout
        path='/partner-registration'
        component={SupplierView}
        layout={PublicLayout}
        exact
        {...props}
      /> */}
      <RouteWithLayout
        path='/waitlist'
        component={JoinView}
        layout={PublicLayout}
        exact
        {...props}
      />
      <RouteWithLayout
        path='/cookie-policy'
        component={CookiePolicy}
        layout={PublicLayout}
        exact
        {...props}
      />
      <RouteWithLayout
        path='/privacy-policy'
        component={PrivacyPolicy}
        layout={PublicLayout}
        exact
        {...props}
      />
      <RouteWithLayout
        path='/terms'
        component={Terms}
        layout={PublicLayout}
        exact
        {...props}
      />
      <Route
        path='/admin'
        component={(rest) => (
          <DashboardComponent {...props} {...rest} mutate={authMutate} />
        )}
      />

      <Route path='/not-found' exact>
        <NotFoundView {...props} />
      </Route>
      <Redirect from='*' to='/not-found' status='404' />
    </Switch>
  )
}

export default Routes
