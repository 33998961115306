import React from 'react'
import { useSelector } from 'react-redux'
import {
  AboutGrid,
  ApplyImageAds,
  Header,
  Section,
  SectionFullWidth,
  Spacer,
  Team,
} from '../components'

const aboutContent = {
  title: 'Led by the brightest minds in the IoT and clean energy ecosystem',
  body: [],
  image: '/images/team.jpg',
}

function About({ team }) {
  const { about } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={about?.header?.title}
        overline={about?.header?.overline}
        image={about?.header?.featuredImage}
        overlay
        light
      />
      <Section narrow>
        <AboutGrid {...about?.main?.section1} />
      </Section>
      <Section disablePadding>
        <Header
          title={about?.main?.section2?.title}
          overline={about?.main?.section2?.overline}
          body={about?.main?.section2?.subtitle}
          image={about?.main?.section2?.featuredImage}
          bodyVariant='h6'
          reverse
        />
      </Section>
      <Spacer size={16} />
      <Section disablePadding alternate>
        {/* <ApplyImageAds
          title='Freedisity Mobile App Coming Soon'
          image='/images/app-ads.png'
          subtitle='Get ready to discover all the benefits and offers of Freedisity! Gas booking now made easy with a mobile app. Rewards, Online Payments, order tracking and more!'
          alignLeft
        /> */}
      </Section>
      {/*
      <SectionFullWidth dark padding>
       <Team items={team} />
      </SectionFullWidth>
      */}
    </div>
  )
}

export default About
