import React from 'react'

import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createStyles, makeStyles } from '@mui/styles'
import TextField from '@mui/material/TextField'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    normalInput: {
      '&.Mui-focused fieldset': {
        border: `2px solid ${theme.palette.secondary.main}`, // focus
      },
      '&.MuiInputLabel-root': {
        '&.Mui-focused': {
          color: `${theme.palette.secondary.main} !important`,
        },
      },
    },
    lightInput: {
      '& .MuiOutlinedInput-root': {
        color: 'rgb(255 255 255 / 87%) !important',
        '& fieldset': {
          transition: 'all 300ms ease',
          borderColor: 'rgb(255 255 255 / 47%)', // default
        },
        '&.Mui-focused fieldset': {
          border: `2px solid ${theme.palette.secondary.main}`, // focus
          color: 'rgba(255,255,255,.8) !important',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          //   borderColor: `${theme.palette.secondary.main} !important`,
          border: `2px solid ${theme.palette.secondary.main}`,
        },
      },
      '& .MuiInputLabel-root': {
        color: 'rgba(255,255,255,.8) !important',

        '&.Mui-focused': {
          color: `${theme.palette.secondary.main} !important`,
        },
      },
    },
  })
)

function CustomOutlineTextField({ lightInput, ...props }) {
  const classes = useStyles()
  return (
    <TextField
      className={clsx(
        props.className,
        lightInput ? classes.lightInput : classes.normalInput
      )}
      {...props}
    />
  )
}

CustomOutlineTextField.defaultProps = {
  lightInput: false,
}

CustomOutlineTextField.propTypes = {
  className: PropTypes.string,
  lightInput: PropTypes.bool,
}

export default CustomOutlineTextField
