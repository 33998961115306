import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Header, Section, ApplyImageAds, CustomImage } from '../components'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import SupplyForm from '../components/form/SupplyForm'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: '100%',
    },
    content: {
      textAlign: 'start',
    },
    title: {
      fontWeight: 'bolder !important',
      color: theme.palette.primary.main,
    },
  })
)

function Join() {
  const classes = useStyles()
  const { waitlist } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={waitlist?.header?.title}
        overline={waitlist?.header?.overline}
        image={waitlist?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <Box sx={{ p: 2 }}>
          <Grid
            container
            spacing={5}
            alignItems='center'
            className={classes.content}
          >
            <Grid item xs={12} sm={6} data-aos='fade-right'>
              <Typography variant='h4' component='h1' className={classes.title}>
                {waitlist?.main?.section1?.title}
              </Typography>

              <Typography color='textSecondary' paragraph>
                {waitlist?.main?.section1?.subtitle}
              </Typography>
              <CustomImage
                src={waitlist?.main?.section1?.featuredImage}
                className={classes.image}
                lazy
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.imageContainer}
              data-aos='fade-left'
            >
              <SupplyForm />
            </Grid>
          </Grid>
        </Box>
      </Section>
    </div>
  )
}

export default Join
