import { createSlice } from '@reduxjs/toolkit'

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: [
    {
      title: 'Home',
      href: '/',
    },
    {
      title: 'About Us',
      href: '/about-us',
    },
    {
      title: 'Contact Us',
      href: '/contact-us',
    },
    {
      title: 'Join the Waitlist',
      href: '/waitlist',
      button: true,
    },
  ],
  reducers: {
    setPages: (state, { payload }) => {
      state = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPages } = pagesSlice.actions

export default pagesSlice.reducer
