import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

function MiniFooter({ color, sx }) {
  const year = new Date().getFullYear()
  return (
    <Box sx={{ width: '100%', pt: 5, ...sx }}>
      <Divider variant='fullWidth' sx={{ borderColor: `${color}4d`, my: 2 }} />
      <Typography variant="body1" color={color}>
        {`Copyright © ${year} Freedisity Limited. All Rights Reserved.`}
      </Typography>
    </Box>
  )
}

export default MiniFooter

MiniFooter.defaultProps = {
  color: '#ffffff',
  sx: {},
}

MiniFooter.propTypes = {
  color: PropTypes.string,
  sx: PropTypes.object,
}
