import { Route, Redirect } from 'react-router-dom'
import Preloader from '../components/Preloader'
import { PrivateLayout } from '../layout'

const PrivateRoute = (props) => {
  const { children: Component, isAuth } = props
  return (
    <Route {...props}>
      {isAuth ? (
        <PrivateLayout>
          <Component {...props} />
        </PrivateLayout>
      ) : (
        <Redirect to='/admin' />
      )}
    </Route>
  )
}

export default PrivateRoute
