import { makeStyles, createStyles } from '@mui/styles'

import BannerCarousel from '../components/Carousel'
import {
  ApplyImageAds,
  Header,
  Section,
  SectionFullWidth,
  // Solutions,
} from '../components'
import FAQs from '../components/FAQs'
import { useSelector } from 'react-redux'
import Hero from '../components/Hero'

const useStyles = makeStyles((theme) =>
  createStyles({
    section: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '20px !important',
        paddingLeft: '20px !important',
      },
    },
    alternate: {
      backgroundColor: 'rgb(247, 249, 250)',
    },
    mapStyle: {
      width: '100%',
      height: 400,
      objectFit: 'contain',
    },
    mapSection: {
      backgroundColor: 'white !important',
    },
  })
)

// const features = [
//   {
//     caption: 'BULK PREMIUM SUPPLY',
//     icon: '/images/illustrations/supply.svg',
//     body: [
//       'Are you a bulk, high volume LPG consumer? Own a restaurant, hotel, factory, etc.? Let’s provide reliable bulk LPG supply with flexible payment methods to suit your needs.',
//       'You too can start a profitable LPG business. Partner with us',
//     ],
//     form: true,
//   },
//   {
//     caption: 'BECOME A DISTRIBUTOR',
//     icon: '/images/illustrations/note.svg',
//     body: [
//       'Our packaged LPG products are available at all Oando retail stations.',
//     ],
//     form: true,
//   },
//   {
//     caption: 'CYLINDER EXCHANGE SCHEME',
//     icon: '/images/illustrations/exchange.svg',
//     body: [
//       'With N9000 refundable deposit, our CES gives you a reliable supply of cooking gas, discounted refills and free cylinder maintenance.',
//     ],
//     featured_image: '/images/cylinder1.png',
//   },
//   {
//     caption: 'PAY AS YOU GAS',
//     icon: '/images/illustrations/pay.svg',
//     body: [
//       'We maintain a growing network of skid mounted refill plants where you can dispense LPG for any amount you can afford.',
//     ],
//     featured_image: '/images/cylinder2.png',
//   },
// ]

function Home() {
  const { home, faq, ads } = useSelector((state) => state.content)

  const mapFaqs = {
    ...faq?.header,
    list: [faq?.main[0]],
    steps: faq?.main?.slice(1, faq.main?.length),
  }

  const classes = useStyles()
  return (
    <div>
      {/* <BannerCarousel banners={home.header} /> */}
      <Hero data={home.header} />
      <SectionFullWidth backgroundColor={'white'}>
        {/* <Solutions features={features} /> */}
        <Header
          title={home?.main?.section1?.title}
          // overline='Why Freedisity Gas App'
          body={[home?.main?.section1?.subtitle]}
          image={home?.main?.section1?.featureImage}
          bodyVariant='h6'
          imageStyle={classes.mapStyle}
          buttonLink={home?.main?.section1?.buttonLink}
          buttonText={home?.main?.section1?.buttonText}
          buttonVariant='outlined'
          reverse={false}
        />
      </SectionFullWidth>
      <SectionFullWidth dark={true} padding={true} withBg={true}>
        <Header
          title={home?.main?.section2?.title}
          titleVariant="h2"
          overline={home?.main?.section2?.overline}
          body={[home?.main?.section2?.subtitle]}
          image={home?.main?.section2?.featureImage}
          bodyVariant='h6'
          buttonLink={home?.main?.section2?.buttonLink}
          buttonText={home?.main?.section2?.buttonText}
          buttonVariant='contained'
          light
          reverse
        />
      </SectionFullWidth>
      <Section disablePadding alternate>
        <ApplyImageAds
          title={ads?.title}
          image={ads?.featured_image}
          subtitle={ads?.subtitle}
        // alignLeft
        />
      </Section>
      <SectionFullWidth dark padding alternate>
        <FAQs data={mapFaqs} />
      </SectionFullWidth>
    </div>
  )
}

export default Home
