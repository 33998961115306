import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { Header, Section, ApplyImageAds, CustomImage } from '../components'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import DistributorForm from '../components/form/DistributorForm'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) =>
  createStyles({
    image: {
      width: '100%',
    },
    content: {
      textAlign: 'start',
    },
    title: {
      fontWeight: 'bolder !important',
      color: theme.palette.primary.main,
    },
  })
)

// const content = {
//   caption: 'BECOME A PARTNER',
//   body: ['Our services is available to all local distributors'],
// }

function Supplier() {
  const classes = useStyles()
  const { partner, ads } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={partner?.header?.title}
        overline={partner?.header?.overline}
        image={partner?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <Box sx={{ p: 2 }}>
          <Grid
            container
            spacing={5}
            alignItems='center'
            className={classes.content}
          >
            <Grid item xs={12} sm={6} data-aos='fade-right'>
              <Typography variant='h4' component='h1' className={classes.title}>
                {partner?.main?.section1?.title}
              </Typography>

              <Typography color='textSecondary' paragraph>
                {partner?.main?.section1?.subtitle}
              </Typography>
              <CustomImage
                src={partner?.main?.section1?.featuredImage}
                className={classes.image}
                lazy
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.imageContainer}
              data-aos='fade-left'
            >
              <DistributorForm />
            </Grid>
          </Grid>
        </Box>
      </Section>
      <Section disablePadding alternate>
        <ApplyImageAds
          title={ads?.title}
          image={ads?.featured_image}
          subtitle={ads?.subtitle}
          alignLeft
        />
      </Section>
    </div>
  )
}

export default Supplier
