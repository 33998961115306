import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@mui/styles'
import { useSelector } from 'react-redux'
import Container from '@mui/material/Container'
import useMediaQuery from '@mui/material/useMediaQuery'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Grid from '@mui/material/Grid'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import { CustomImage, NewLetterForm } from '..'
import MiniFooter from './MiniFooter'
import GotoTop from './GotoTop'
import { openLink } from '../../utils'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(12, 0),
    // [theme.breakpoints.up('md')]: {
    //   padding: theme.spacing(6, 0),
    // },
    background: theme.palette.common.white,
  },
  footerContainer: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  logoContainerItem: {
    paddingTop: 0,
  },
  logoImage: {
    width: '100%',
    height: 50,
    textAlign: 'start',
    objectFit: 'contain',
    paddingBottom: 10
  },
  socialIcon: {
    padding: 0,
    marginRight: theme.spacing(1),
    '&:hover': {
      background: 'transparent',
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  icon: {
    fontSize: 24,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  listItemText: {
    margin: 0,
    padding: 0,
  },
  item: {
    paddingRight: theme.spacing(2.5),
    paddingLeft: theme.spacing(2.5),
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: theme.spacing(2.5),
    },
  },
  paragraph: {
    fontWeight: 'normal',
    letterSpacing: 1,
    paddingLeft: 0,
    marginBottom: `${theme.spacing(0.4)} !important`,
    '&:hover': {
      color: 'gray',
    },
  },
  copyright: {
    fontWeight: 'normal',
    letterSpacing: 0.2,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  logo: {
    height: '40px !important',
  },
  link: {
    display: 'block',
    textDecoration: 'none !important',
    color: "black !important",
    paddingTop: 5,
    paddingBottom: 5,
    transition: 'color 300ms ease-in',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 500,
  },
}))

const Footer = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  const { footer, contact } = useSelector((state) => state.content)

  const { contacts, location, className, ...rest } = props
  const desktop = useMediaQuery(theme.breakpoints.up('sm'))

  const [scrollDir, setScrollDir] = React.useState('down')

  React.useEffect(() => {
    const threshold = 0
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? 'down' : 'up')
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollDir])

  const handleScroll = () => {
    if (scrollDir === 'down') {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } else {
      window.scroll({
        top: document.body.offsetHeight,
        left: 0,
        behavior: 'smooth',
      })
    }
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Container className={classes.footerContainer}>
        <Grid
          container
          direction='row'
          justifyContent='space-between'
          alignItems='flex-start'
          spacing={4}
        >
          <Grid item xs={12} sm={3}>
            <Link
              component={RouterLink}
              to='/'
              style={{ textDecoration: 'none' }}
              className={classes.logoContainer}
            >
              <CustomImage
                className={classes.logoImage}
                src='/images/logos/Full-logo.svg'
                alt='Freedisity'
              />
            </Link>
            <Typography
              variant='body1'
              className={classes.paragraph}
              gutterBottom
            >
              {footer?.about}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <List subheader={<Typography variant="body1" fontWeight={700} gutterBottom>Quick Links</Typography>} disablePadding>
              {footer.quickLinks?.map(({ title, href }, index) => (
                <Link
                  key={title}
                  className={clsx(classes.link, {
                    [classes.active]: href === location?.pathname,
                  })}
                  component={RouterLink}
                  to={href}
                >
                  {title}
                </Link>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography
              variant='body1'
              className={classes.paragraph}
              fontWeight={700}
              gutterBottom
            >
              Join our waitlist
            </Typography>
            <NewLetterForm />
          </Grid>
          <Grid item xs={12} sm={3}>
            <List disablePadding>
              {contact?.main?.contact?.map((contact, index) => (
                <ListItem key={index} sx={{ p: 0, m: 0 }} disableGutters>
                  <ListItemText
                    primary={
                      <Typography paragraph className={classes.paragraph}>
                        {contact.value}
                      </Typography>
                    }
                    className={classes.listItemText}
                  />
                </ListItem>
              ))}
              <ListItem sx={{ p: 0, m: 0 }} disableGutters>
                {footer?.socials?.map((item, index) => (
                  <IconButton
                    key={index}
                    className={classes.socialIcon}
                    onClick={() => openLink(item?.link)}
                  >
                    {item?.name === 'facebook' ? (
                      <FacebookIcon style={{ color: "#3B5A9A" }} className={classes.icon} />
                    ) : item?.name === 'instagram' ? (
                      <InstagramIcon style={{ color: "#DB3B7D" }} className={classes.icon} />
                    ) : (
                      <TwitterIcon style={{ color: "#02AEF0" }} className={classes.icon} />
                    )}
                  </IconButton>
                ))}
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <MiniFooter color={"black"} />
        <GotoTop state={scrollDir} handleClick={handleScroll} />
      </Container>
    </div>
  )
}

Footer.propTypes = {
  className: PropTypes.string,
  contacts: PropTypes.array.isRequired,
}

export default Footer
