import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import Button from '@mui/material/Button'
import { LearnMoreLink, Section, SectionHeader } from '../components'

const useStyles = makeStyles((theme) =>
  createStyles({
    label: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
  })
)

function NotFound(props) {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div>
      <Section>
        <div className={classes.formContainer}>
          <SectionHeader
            label='404'
            title='Uh oh.'
            subtitle={
              <span>
                There’s nothing here, but if you feel this is an error please{' '}
                <LearnMoreLink
                  title='let us know'
                  href='/contact-us'
                  typographyProps={{ variant: 'h6' }}
                />
              </span>
            }
            titleProps={{
              variant: 'h3',
            }}
            labelProps={{
              color: 'secondary',
              className: classes.label,
              variant: 'h5',
            }}
            ctaGroup={[
              <Button
                size='large'
                variant='contained'
                color='primary'
                onClick={() => history.goBack()}
              >
                Go Back
              </Button>,
            ]}
            disableGutter
          />
        </div>
      </Section>
    </div>
  )
}

export default NotFound
