import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import palette from './palette'
import MavenPro from 'typeface-maven-pro'

const theme = responsiveFontSizes(
  createTheme({
    palette,
    layout: {
      contentWidth: 1140,
    },
    typography: {
      fontFamily: 'Maven Pro',
    },
    components: {
      // Name of the component
      MuiCssBaseline: {
        '@global': {
          '@font-face': [MavenPro],
        },
      },
      MuiTypography: {
        styleOverrides: {
          overline: {
            fontSize: '1rem',
            fontWeight: '300',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '5px 0',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          root: {
            borderRadius: 16,
            fontWeight: 600,
            textTransform: 'capitalize',
          },
          containedSecondary: {
            color: 'white',
          },
        },
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  })
)

export default theme
