import { createSlice } from '@reduxjs/toolkit'

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState: [
    {
      name: 'Address',
      value: '2 Fulfilling Word Close Off Peter Odili Road PHC',
    },
    {
      name: 'Mail',
      value: 'customercare@freedisity.com',
    },
    {
      name: 'Phone',
      value: '+234 802 258 7797',
    },
  ],
  reducers: {
    setContacts: (state, { payload }) => {
      state = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setContacts } = contactsSlice.actions

export default contactsSlice.reducer
