import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import { createStyles, makeStyles } from '@mui/styles'
import { fontSize } from '@mui/system'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(20, 0),
      backgroundImage: "url(/images/map.svg)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover"
    },
    heading: {
      fontWeight: 900,
      color: theme.palette.primary.dark,
      fontSize: 32
    },
    body: {
      color: 'rgb(255 255 255 / 90%) !important',
      fontWeight: 100,
    },
    primary: {
      color: 'rgb(255 255 255 / 90%) !important',
      fontWeight: '700 !important',
      marginBottom: `10px !important`,
    },
    contrast: {
      color: theme.palette.common.white,
    },
    caption: {
      color: 'rgba(255,255,255,0.8)',
      letterSpacing: 2,
      textTransform: 'uppercase',
      marginBottom: theme.spacing(2),
    },
    accordionbody: {
      color: '#5A7184',
    },
    paper: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.common.white,
      borderTopWidth: 4,
      borderTopColor: '#22DA76',
      borderTopStyle: 'solid',
      borderTopRightRadius: 8,
      borderTopLeftRadius: 8,
    },
    noshadow: {
      boxShadow: 'none',
    },
    button: {
      marginTop: `${theme.spacing(2)} !important`,
      color: 'white !important',
      borderColor: 'white !important',
    },
  })
)

function FAQs({ data }) {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)
  const { steps = [] } = data

  React.useLayoutEffect(() => {
    setExpanded('panel0')
  }, [])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth='lg'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={5}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='body1' className={classes.caption} gutterBottom>
              {data?.overline}
            </Typography>
            <Typography
              component='h1'
              variant='h2'
              className={`${classes.heading} ${classes.contrast}`}
              gutterBottom
            >
              {data?.title}
            </Typography>
            <Typography
              component='p'
              variant='h5'
              className={classes.body}
              gutterBottom
            >
              {data?.subtitle}
            </Typography>

            <List dense>
              {data?.list?.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <CheckCircleIcon fontSize='large' style={{ color: "white" }} />
                  </ListItemIcon>
                  <ListItemText
                    classes={{
                      primary: classes.primary,
                      secondary: classes.body,
                    }}
                    primary={item?.title}
                    secondary={item?.subtitle}
                    primaryTypographyProps={{
                      style: {
                        fontSize: 24
                      }
                    }}
                    secondaryTypographyProps={{
                      style: {
                        fontSize: 16
                      }
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={0} className={classes.paper}>
              {steps?.map((item, index) => (
                <Accordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  classes={{ root: classes.noshadow }}
                >
                  <AccordionSummary
                    expandIcon={
                      expanded === `panel${index}` ? <RemoveIcon /> : <AddIcon />
                    }
                    aria-controls='panel1a-content'
                    id='panel1a-header'
                  >
                    <Typography variant='h5' className={classes.heading}>
                      {item?.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant='body1' className={classes.accordionbody}>
                      {item?.subtitle}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Paper>
            {data?.buttonText && (
              <Button
                variant='outlined'
                component={RouterLink}
                to={data?.buttonLink}
                size='large'
                endIcon={<ArrowRightIcon fontSize='large' />}
                className={classes.button}
              >
                {data?.buttonText}
              </Button>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default FAQs
