import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    padding: `${theme.spacing(6, 0)} !important`,
    [theme.breakpoints.up('sm')]: {
      padding: `${theme.spacing(12, 0)} !important`,
    },
  },
  fullWidth: {
    maxWidth: '100% !important',
  },
  disablePadding: {
    padding: '0px !important',
  },
  alternate: {
    width: '100%',
    backgroundColor: 'rgb(247, 249, 250)',
  },
}))

/**
 * Component to display the sections
 *
 * @param {Object} props
 */
const Section = (props) => {
  const {
    children,
    fullWidth,
    narrow,
    disablePadding,
    alternate,
    className,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <div className={clsx(alternate ? classes.alternate : {})}>
      <Container
        component='section'
        maxWidth={narrow ? 'md' : 'lg'}
        className={clsx(
          'section',
          classes.root,
          fullWidth ? classes.fullWidth : {},
          disablePadding ? classes.disablePadding : {},
          className
        )}
        {...rest}
      >
        {children}
      </Container>
    </div>
  )
}

Section.defaultProps = {
  alternate: false,
}

Section.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  /**
   * Should show narrow sections
   */
  narrow: PropTypes.bool,
  /**
   * Should the section be full width
   */
  fullWidth: PropTypes.bool,
  /**
   * Should the section render with no padding
   */
  disablePadding: PropTypes.bool,

  alternate: PropTypes.bool,
}

export default Section
