import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import MiniFooter from '../components/Footer/MiniFooter'
import { CustomImage } from '../components'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '40px !important',
  },
}))

const AuthLayout = ({ children }) => {
  const classes = useStyles()
  return (
    <Box
      component='main'
      sx={{
        overflowX: 'hidden',
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link
        component={RouterLink}
        to='/'
        style={{ textDecoration: 'none', marginBottom: 24 }}
      >
        <CustomImage
          className={classes.logo}
          src='/images/logos/Full-logo.svg'
          alt='Freedisity'
          lazy={false}
        />
      </Link>
      {children}
      <MiniFooter
        color='black'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      />
    </Box>
  )
}

export default AuthLayout
