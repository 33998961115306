import React from 'react'
import PropTypes from 'prop-types'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CustomImage } from '..'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    flexCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        height: '100% !important',
      },
      marginBottom: 20,
      marginTop: 20,
      objectFit: 'cover',
      borderRadius: 18,
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: '900 !important',
      textTransform: 'capitalize',
      lineHeight: 1,
    },
    paragraph: {
      fontSize: '18px !important',
    },
  })
)

function AboutGrid({ title, subtitle, body, featuredImage }) {
  const classes = useStyles()
  return (
    <Box className={classes.flexCenter} sx={{ px: 2 }}>
      <Typography variant='h4' component='h1' className={classes.title}>
        {title}
      </Typography>
      <Typography color='textSecondary' className={classes.paragraph} paragraph>
        {subtitle}
      </Typography>
      <CustomImage src={featuredImage} className={classes.image} lazy />
      {body?.length
        ? body?.map((text, index) => (
            <Typography
              key={index}
              color='textSecondary'
              className={classes.paragraph}
              paragraph
            >
              {text}
            </Typography>
          ))
        : null}
    </Box>
  )
}

AboutGrid.defaultProps = {
  body: [],
}

AboutGrid.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  body: PropTypes.array.isRequired,
  image: PropTypes.string,
}

export default AboutGrid
