import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'

// const StyledLazyLoadImage = styled.LazyLoadImage`
//   width: '100%';
//   height: '100%';
//   display: 'block';
// `

const StyleImg = styled.img`
  width: 100%;
  height: 100%;
`

// const useStyles = makeStyles(() => ({
//   root: {
//     width: '100%',
//     height: '100%',
//   },
//   dBlock: {
//     display: 'block',
//   },
// }));

/**
 * Component to display the images
 *
 * @param {Object} props
 */
const CustomImage = (props) => {
  const { src, srcSet, alt, lazy, lazyProps, className, ...rest } = props

  if (lazy) {
    return (
      <LazyLoadImage
        className={clsx('image', className)}
        alt={alt}
        src={src}
        srcSet={srcSet}
        effect='opacity'
        {...lazyProps}
        {...rest}
      />
    )
  }

  return (
    <StyleImg
      className={clsx('image', className)}
      alt={alt}
      src={src}
      srcSet={srcSet}
      {...rest}
    />
  )
}

CustomImage.defaultProps = {
  alt: '...',
  lazy: true,
  lazyProps: {
    width: 'auto',
    height: 'auto',
  },
}

CustomImage.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Source of the image
   */
  src: PropTypes.string.isRequired,
  /**
   * Source set for the responsive images
   */
  srcSet: PropTypes.string,
  /**
   * Image title
   */
  alt: PropTypes.string,
  /**
   * Should lazy load the image
   */
  lazy: PropTypes.bool,
}

export default CustomImage
