import Box from '@mui/material/Box'

function PrivateLayout({ children }) {
  return (
    <Box component='main' sx={{ overflowX: 'hidden' }}>
      {children}
    </Box>
  )
}

export default PrivateLayout
