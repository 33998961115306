import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import TwitterIcon from '@mui/icons-material/Twitter'
import { openLink } from '../../utils'

const useStyles = makeStyles((theme) =>
  createStyles({
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    container: {
      textAlign: 'center',
    },
    item: {
      textAlign: 'center',
    },
    avatar: {
      width: '100px !important',
      height: '100px !important',
      textAlign: 'center !important',
      margin: '10px auto',
    },
    heading: {
      fontWeight: '900 !important',
      textTransform: 'capitalize',
      lineHeight: 1,
    },
    title: {
      textTransform: 'capitalize',
    },
    subtitle: {
      color: 'rgb(255 255 255 / 56%)',
      textTransform: 'capitalize !important',
    },
    icon: {
      color: 'white',
    },
  })
)

function Team({ items }) {
  const classes = useStyles()
  return (
    <Container maxWidth='lg' className={classes.container}>
      <Typography
        variant='h4'
        component='h1'
        className={classes.heading}
        color='white'
      >
        Our Team
      </Typography>
      <Typography variant='overline' color='secondary' gutterBottom>
        Management Team
      </Typography>
      <Grid spacing={2} alignItems='center' container>
        {items?.map((item, index) => (
          <Grid item key={index} xs={6} sm={6} md={3} className={classes.item}>
            <Avatar
              alt={item?.name}
              src={item?.avatar}
              className={classes.avatar}
              sizes='large'
            />
            <Typography variant='h6' color='white' className={classes.title}>
              {item?.name}
            </Typography>
            <Typography variant='overline' className={classes.subtitle}>
              {item?.position}
            </Typography>
            <div className={classes.flex}>
              {item?.socials?.map((social, index) => (
                <IconButton key={index} onClick={() => openLink(social?.url)}>
                  {social?.type === 'facebook' ? (
                    <FacebookIcon className={classes.icon} />
                  ) : social?.type === 'linkedin' ? (
                    <LinkedInIcon className={classes.icon} />
                  ) : (
                    <TwitterIcon className={classes.icon} />
                  )}
                </IconButton>
              ))}
            </div>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default Team
