import React from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { CustomImage } from '../..'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
    marginBottom: `${theme.spacing(4)} !important`,
    padding: `${theme.spacing(6)} 0 !important`,
  },
  overlay: {
    backgroundColor: theme.palette.primary.main,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundBlendMode: 'luminosity',
    position: 'relative',
  },
  overlayCover: {
    // width: '100%',
    // height: '100%',
    // background: 'black',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // opacity: 0.5,
  },
  container: {
    maxWidth: theme.layout.contentWidth,
    margin: '0 auto',
    zIndex: 1,
  },
  item: {
    // backgroundColor: theme.palette.primary.dark,

    '&:nth-child(1)': {
      paddingRight: theme.spacing(2.5),
    },
    '&:nth-child(2)': {
      paddingLeft: theme.spacing(2.5),
      // paddingTop: theme.spacing(2.5),
      paddingBottom: theme.spacing(2.5),
    },
  },
  centerItem: {
    textAlign: 'center',
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    borderRadius: 8,
  },
  title: {
    fontWeight: '900 !important',
    textTransform: 'capitalize',
    lineHeight: 1,
  },
  button: {
    marginTop: `${theme.spacing(1.5)} !important`,
  },
}))

function Header(props) {
  const classes = useStyles()
  const theme = useTheme()
  const {
    title,
    titleVariant,
    bodyVariant,
    overline,
    image,
    imageStyle,
    body,
    buttonText,
    buttonLink,
    buttonVariant,
    reverse = null,
    light = false,
    overlay,
  } = props

  return (
    <div
      className={clsx(classes.root, { [classes.overlay]: overlay })}
      style={
        overlay
          ? {
            backgroundImage: `url(${image})`,
          }
          : {}
      }
    >
      <Grid
        container
        alignItems='center'
        justifyContent={'center'}
        className={classes.container}
        direction={
          reverse && !overlay ? 'row-reverse' : !overlay ? 'row' : 'column'
        }
      >
        {!overlay ? (
          <Grid
            data-aos={reverse ? 'fade-left' : 'fade-right'}
            item
            xs={12}
            sm
            className={classes.item}
          >
            <CustomImage
              lazy
              src={image}
              alt='...'
              className={imageStyle ? imageStyle : classes.image}
            />
          </Grid>
        ) : (
          <div className={classes.overlayCover} />
        )}
        <Grid
          data-aos={
            reverse && !overlay
              ? 'fade-right'
              : !overlay
                ? 'fade-left'
                : 'fade-up'
          }
          item
          xs={12}
          sm
          className={clsx(classes.item, { [classes.centerItem]: overlay })}
          sx={{
            textAlign: reverse ? 'start' : reverse === false ? 'end' : 'center',
            paddingRight: 4,
            paddingLeft: 4,
          }}
        >
          {overline && (
            <Typography variant='overline' color={overlay ? "white" : "secondary"}>
              {overline}
            </Typography>
          )}
          <Typography
            variant={titleVariant || 'h3'}
            component='h1'
            className={classes.title}
            color={light ? 'white' : 'primary'}
            gutterBottom
          >
            {title}
          </Typography>
          <Typography
            variant={bodyVariant}
            color={light ? 'white' : 'textSecondary'}
            gutterBottom
          >
            {body}
          </Typography>
          {buttonText && (
            <Button
              color={light ? 'secondary' : 'primary'}
              variant={buttonVariant ? buttonVariant : 'contained'}
              component={RouterLink}
              to={buttonLink}
              size='large'
              className={classes.button}
              endIcon={<ArrowRightIcon fontSize='small' />}
            >
              {buttonText}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Header
