import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Header, Section } from "../components";
import CustomHtml from "../components/html";

function CookiePolicy() {
  const theme = useTheme();
  const { cookiePolicy } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={cookiePolicy?.header?.title}
        overline={cookiePolicy?.header?.overline}
        image={cookiePolicy?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <CustomHtml html={cookiePolicy.main.data} theme={theme} />
      </Section>
    </div>
  );
}

export default CookiePolicy;
