import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { LoginForm } from '../../components/form'

function Login({ mutate, history, ...props }) {
  console.log(props)
  return (
    <Box>
      <Typography
        component='h1'
        variant='h4'
        fontWeight='bolder'
        sx={{ mb: 1 }}
      >
        Login to Admin
      </Typography>
      <LoginForm mutate={mutate} history={history} />
    </Box>
  )
}

export default Login
