import React from 'react'
import Box from '@mui/material/Box'
import Fab from '@mui/material/Fab'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

function GotoTop({ state, handleClick }) {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 1000,
        right: { xs: '5%', md: '10%' },
        bottom: { xs: '3%', md: '5%' },
      }}
    >
      <Fab
        onClick={handleClick}
        size='large'
        color='primary'
        aria-label='add'
      >
        {state === 'down' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </Fab>
    </Box>
  )
}

export default GotoTop
