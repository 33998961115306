import * as React from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import SwipeableTemporaryDrawer from './SideBar'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { CustomImage } from '..'

const useStyles = makeStyles((theme) => ({
  logo: {
    height: '40px !important',
  },
  link: {
    transition: 'color 300ms ease-in',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 500,
  },
}))

const ResponsiveAppBar = ({ pages, location }) => {
  const classes = useStyles()

  return (
    <AppBar elevation={0} position='static' color='transparent'>
      <Container maxWidth='lg'>
        <Toolbar disableGutters>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
            }}
          >
            <SwipeableTemporaryDrawer
              pages={pages}
              location={location}
              button={(props) => (
                <IconButton
                  {...props}
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  color='inherit'
                >
                  <MenuIcon />
                </IconButton>
              )}
            />
            <Link
              component={RouterLink}
              to='/'
              style={{ textDecoration: 'none' }}
            >
              <CustomImage
                className={classes.logo}
                src='/images/logos/Full-logo.svg'
                alt='Freedisity'
                lazy={false}
              />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <Link
              component={RouterLink}
              to='/'
              style={{ textDecoration: 'none' }}
            >
              <CustomImage
                className={classes.logo}
                src='/images/logos/Full-logo.svg'
                alt='Freedisity'
                lazy={false}
              />
            </Link>
          </Box>

          <Box
            sx={{
              flexGrow: 0,
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center',
            }}
          >
            {pages.map(({ title, href }) => {
              if (title === 'Join the Waitlist') {
                return (
                  <Button
                    key={title}
                    component={RouterLink}
                    to={href}
                    size='large'
                    variant='contained'
                    endIcon={<ArrowRightIcon />}
                  >
                    {title}
                  </Button>
                )
              } else {
                return (
                  <Link
                    key={title}
                    className={clsx(classes.link, {
                      [classes.active]: href === location?.pathname,
                    })}
                    component={RouterLink}
                    to={href}
                    sx={{
                      px: 1.5,
                      display: 'block',
                      textDecoration: 'none',
                      color: "black"
                    }}
                  >
                    {title}
                  </Link>
                )
              }
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default ResponsiveAppBar
