import * as React from 'react'
import clsx from 'clsx'
import { Link as RouterLink } from 'react-router-dom'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { createStyles, makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { CustomImage } from '..'

const useStyles = makeStyles((theme) =>
  createStyles({
    logo: {
      height: '30px !important',
      width: 'auto !important',
    },
    link: {
      padding: '8px 0 !important',
      transition: 'color 300ms ease-in',
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
    active: {
      color: `${theme.palette.secondary.main} !important`,
      fontWeight: 'bolder',
    },
  })
)

export default function SwipeableTemporaryDrawer({
  button: HamBurgerButton,
  pages,
  location,
}) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = (action) => {
    setOpen(action || !open)
  }

  const ListPages = () => (
    <Box
      sx={{ width: 250, p: 3 }}
      role='presentation'
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <CustomImage
        className={classes.logo}
        src='/images/logos/Full-logo.svg'
        alt='Freedisity'
        lazy={false}
      />
      <Divider variant='fullWidth' />
      <List>
        {pages?.map(({ title, href }, index) => {
          if (title === 'Order Now') {
            return (
              <ListItem key={index} sx={{ px: 0 }}>
                <Button
                  component={RouterLink}
                  to={href}
                  variant='contained'
                  color='primary'
                  endIcon={<ArrowRightIcon />}
                >
                  {title}
                </Button>
              </ListItem>
            )
          }
          return (
            <ListItem
              key={index}
              component={RouterLink}
              to={href}
              sx={{ textDecoration: 'none', color: 'black' }}
              className={clsx(classes.link, {
                [classes.active]: href === location?.pathname,
              })}
            >
              <ListItemText primary={title} />
            </ListItem>
          )
        })}
      </List>
    </Box>
  )

  return (
    <React.Fragment>
      <HamBurgerButton onClick={(e) => toggleDrawer()} />
      <SwipeableDrawer
        anchor='left'
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <ListPages />
      </SwipeableDrawer>
    </React.Fragment>
  )
}
