import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { Header, Section } from "../components";
import CustomHtml from "../components/html";

function PrivacyPolicy() {
  const theme = useTheme();
  const { privacyPolicy } = useSelector((state) => state.content)

  return (
    <div>
      <Header
        title={privacyPolicy?.header?.title}
        overline={privacyPolicy?.header?.overline}
        image={privacyPolicy?.header?.featuredImage}
        overlay
        light
      />
      <Section>
        <CustomHtml html={privacyPolicy.main.data} theme={theme} />
      </Section>
    </div>
  );
}

export default PrivacyPolicy;
