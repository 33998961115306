import { createSlice } from '@reduxjs/toolkit'

export const teamSlice = createSlice({
  name: 'team',
  initialState: [
    {
      name: 'BEN STOKMAN',
      position: 'Chief Executive Officer',
      avatar: '/images/avatar00.jpeg',
      socials: [
        {
          type: 'facebook',
          url: 'https://facebook.com',
        },
        {
          type: 'linkedin',
          url: 'https://linkedin.com',
        },
      ],
    },
    {
      name: 'DAVID RAYNER',
      position: 'Chief Financial Officer',
      avatar: '/images/avatar01.jpeg',
      socials: [
        {
          type: 'facebook',
          url: 'https://facebook.com',
        },
        {
          type: 'linkedin',
          url: 'https://linkedin.com',
        },
      ],
    },
    {
      name: 'MUMUNI DAGAZAU',
      position: 'Chief Operating Officer',
      avatar: '/images/avatar02.jpeg',
      socials: [
        {
          type: 'facebook',
          url: 'https://facebook.com',
        },
        {
          type: 'linkedin',
          url: 'https://linkedin.com',
        },
      ],
    },
    {
      name: 'LILIAN IKOKWU',
      position: 'Chief Marketing Officer',
      avatar: '/images/avatar03.jpeg',
      socials: [
        {
          type: 'facebook',
          url: 'https://facebook.com',
        },
        {
          type: 'linkedin',
          url: 'https://linkedin.com',
        },
      ],
    },
  ],
  reducers: {
    setTeam: (state, { payload }) => {
      state = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTeam } = teamSlice.actions

export default teamSlice.reducer
