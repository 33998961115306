import React from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import ButtonBase from '@mui/material/ButtonBase'
import { CustomImage, FeatureCard, Spacer } from '..'
import { DistributorForm, SupplyForm } from '../form'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    card: {
      width: '100%',
    },
    content: {
      textAlign: 'start',
    },
    title: {
      fontWeight: 'bolder !important',
      color: theme.palette.primary.main,
    },
    imageContainer: {
      textAlign: 'center',
    },
    image: {
      height: 300,
    },
  })
)

function Solutions({ features }) {
  const classes = useStyles()
  const [active, setActive] = React.useState(0)

  const toggleActive = (currentIndex) => {
    setActive(currentIndex)
  }

  return (
    <div className={classes.root}>
      <Typography
        variant='overline'
        color='secondary'
        data-aos='fade-up'
        gutterBottom
      >
        SOLUTIONS FOR BUSINESS
      </Typography>
      <Grid container spacing={2} alignItems={'center'} data-aos='fade-up'>
        {features?.map((item, index) => (
          <Grid key={index} item xs={6} sm={6} md={3}>
            <ButtonBase
              sx={{ width: '100%', cursor: 'pointer' }}
              onClick={() => toggleActive(index)}
              centerRipple
            >
              <FeatureCard
                className={classes.card}
                item={item}
                active={active}
                index={index}
              />
            </ButtonBase>
          </Grid>
        ))}
      </Grid>

      <Spacer />

      {features.length && (
        <Grid
          container
          spacing={5}
          alignItems='center'
          className={classes.content}
        >
          <Grid item xs={12} sm={6} data-aos='fade-right'>
            <Typography variant='h4' component='h1' className={classes.title}>
              {features[active]?.caption}
            </Typography>

            {features[active]?.body?.map((body, bodyIndex) => (
              <Typography key={bodyIndex} color='textSecondary' paragraph>
                {body}
              </Typography>
            ))}
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.imageContainer}
            data-aos='fade-left'
          >
            {active === 2 || active === 3 ? (
              <CustomImage
                src={features[active]?.featured_image}
                className={classes.image}
                lazy
              />
            ) : active === 0 ? (
              <SupplyForm />
            ) : (
              <DistributorForm />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default Solutions
