import React from 'react'
import PropTypes from 'prop-types'
import { createStyles, makeStyles } from '@mui/styles'
import './youtube.css'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      paddingBottom: theme.spacing(2.5),
      position: 'relative',
      height: 300,
      borderRadius: theme.spacing(1.5),
    },
    iframe: {
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
      position: 'absolute',
    },
  })
)

const YoutubeEmbed = ({ embedId, title }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <iframe
        className={classes.iframe}
        width='100%'
        height='100%'
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title={title}
      />
    </div>
  )
}

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default YoutubeEmbed
