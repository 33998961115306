import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import validate from 'validate.js'
import toast from 'react-hot-toast'
import { useMailChimp } from 'react-use-mailchimp-signup'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import CustomOutlineTextField from './CustomOutlineTextField'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
)

const schema = {
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 120,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },

  phoneNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 11,
    },
  },
  comments: {
    presence: { allowEmpty: true },
    length: {
      maximum: 500,
    },
  },
}
function ContactForm() {
  const classes = useStyles()
  const { error, loading, status, subscribe, message } = useMailChimp({
    action: `https://freedisity.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`,
  })
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  React.useEffect(() => {
    console.log('status', status)
    console.log('error', error)
    console.log('loading', loading)
    console.log('message', message)
  }, [status, error, loading, message])

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState((prevData) => ({
      ...prevData,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false

  const handleChange = (event) => {
    event.persist()

    setFormState((prevData) => ({
      ...prevData,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (formState.isValid) {
      const payload = {
        EMAIL: formState.values?.email,
        MERGE1: formState.values?.fullName,
        MERGE4: formState.values?.phoneNumber,
        MERGE2: formState.values?.comments,
        MERGE7: 'CONTACT US',
      }

      toast.promise(subscribe(payload), {
        loading: 'Sending...',
        success: (data) => {
          // CLEAR FORM
          setFormState((prevData) => ({
            ...prevData,
            isValid: false,
            values: {},
            touched: {},
            errors: {},
          }))
          return 'Thank you for contacting us.'
        },
        error: (err) => {
          console.log(err)
          return 'Something went wrong while submitting, try again'
        },
      })
    }

    setFormState((prevData) => ({
      ...prevData,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  return (
    <form
      name='contact-us-form'
      method='post'
      onSubmit={(e) => handleSubmit(e, subscribe, status)}
      className={classes.root}
    >
      <CustomOutlineTextField
        placeholder='Fullname'
        label='Fullname *'
        variant='outlined'
        size='large'
        name='fullName'
        fullWidth
        helperText={hasError('fullName') ? formState.errors.fullName[0] : null}
        error={hasError('fullName')}
        onChange={handleChange}
        type='text'
        value={formState.values.fullName || ''}
      />

      <CustomOutlineTextField
        placeholder='Phone Number'
        label='Phone Number *'
        variant='outlined'
        size='large'
        name='phoneNumber'
        fullWidth
        helperText={
          hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
        }
        error={hasError('phoneNumber')}
        onChange={handleChange}
        type='text'
        value={formState.values.phoneNumber || ''}
      />
      <CustomOutlineTextField
        placeholder='Email Address'
        label='Email Address *'
        variant='outlined'
        size='large'
        name='email'
        fullWidth
        helperText={hasError('email') ? formState.errors.email[0] : null}
        error={hasError('email')}
        onChange={handleChange}
        type='email'
        value={formState.values.email || ''}
      />
      <CustomOutlineTextField
        placeholder='Comments'
        label='Comments'
        variant='outlined'
        size='large'
        name='comments'
        multiline
        rows={4}
        fullWidth
        onChange={handleChange}
        type='text'
        value={formState.values.comments || ''}
      />
      <LoadingButton
        loading={loading}
        size='large'
        variant='contained'
        type='submit'
        color='primary'
        endIcon={<ArrowRightIcon />}
      >
        Submit
      </LoadingButton>
    </form>
  )
}

export default ContactForm
