import { connect, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './utils'
import Routes from './routes'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useProfile, useSWRFetch } from './hooks'
import { useEffect } from 'react'
import { setAuth, setProfile } from './store/reducer/auth'
import { setContent } from './store/reducer/content'
import { Toaster } from 'react-hot-toast'

const browserHistory = createBrowserHistory()

browserHistory.listen((location) => {
  // Use setTimeout to make sure this runs after React Router's own listener
  setTimeout(() => {
    // Keep default behavior of restoring scroll position when user:
    // - clicked back button
    // - clicked on a link that programmatically calls `history.goBack()`
    // - manually changed the URL in the address bar (here we might want
    // to scroll to top, but we can't differentiate it from the others)
    if (location.action === 'POP') {
      return
    }
    // In all other cases, scroll to top
    window.scrollTo(0, 0)
  })
})

function App(props) {
  const { isAuth, profile } = useSelector((state) => state.auth)

  const {
    data,
    loggedOut,
    loading: dataLoading,
    mutate: authMutate,
  } = useProfile()

  const { data: content } = useSWRFetch('/content/web')

  const dispatch = useDispatch()

  AOS.init({
    duration: 1000,
    once: true,
    delay: 0,
    easing: 'ease-in',
    offset: 0,
  })

  useEffect(() => {
    if (data) {
      dispatch(setProfile(data))
      dispatch(setAuth(true))
    }
    if (loggedOut) {
      dispatch(setAuth(false))
      dispatch(setProfile(null))
    }
    // return () => socket.disconnect();
  }, [data, loggedOut])

  useEffect(() => {
    if (content) {
      dispatch(setContent(content))
    }
  }, [content])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Toaster position='top-center' reverseOrder={false} />
      <Router>
        <Routes
          isAuth={isAuth}
          profile={profile}
          loading={dataLoading}
          authMutate={authMutate}
          {...props}
        />
      </Router>
    </ThemeProvider>
  )
}

export default connect((state) => ({
  pages: state.pages,
  contacts: state.contacts,
  team: state.team,
}))(App)
