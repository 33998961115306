import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import Container from '@mui/material/Container'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

import { CustomImage } from '..'
import './carousel.css'
import { useMediaQuery } from '@mui/material'

const useStyles = makeStyles((theme) =>
  createStyles({
    flexContainer: {
      display: 'flex !important',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: '80% !important',
        // height: 150,
      },
    },
    textContainer: {
      width: 400,
      paddingRight: theme.spacing(4.5),
      paddingLeft: theme.spacing(4.5),
      paddingBottom: theme.spacing(6.5),
    },
    title: {
      fontWeight: '700 !important',
      color: 'white !important',
      fontSize: '2.8rem !important',
      textTransform: 'capitalize',
      [theme.breakpoints.down('sm')]: {
        fontSize: '2.2rem !important',
        marginBottom: `${theme.spacing(1.5)} !important`,
      },
    },
    body: {
      fontSize: '1.5rem !important',
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '1rem !important',
      },
    },
    overlay: {
      position: 'absolute',
      backgroundImage:
        'linear-gradient(to right, rgba(255,0,0,0), rgba(0,0,0,1))',
      width: '100%',
      height: '100%',
      zIndex: 1,
    },
    carouselRoot: {
      backgroundColor: theme.palette.primary.main,
      height: '100%',
    },
  })
)

function BannerCarousel({ banners }) {
  const classes = useStyles()
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      autoPlay={false}
      infiniteLoop
      className={classes.carouselRoot}
    >
      {banners?.map((item, index) => (
        <Container maxWidth='lg' key={index} className={classes.flexContainer}>
          <CustomImage
            src={item?.featureImage}
            alt={item?.caption}
            className={classes.image}
            lazyProps={{
              // width: '100%',
              height: '100%',
            }}
            lazy
          />
          <Box
            className={classes.textContainer}
            sx={{ wordWrap: 'break-word', textAlign: 'end' }}
          >
            <Typography
              variant='h4'
              component='h1'
              className={classes.title}
              gutterBottom
            >
              {item?.title}
            </Typography>
            {Array.isArray(item?.body) ? (
              item?.body.map((text, i) => (
                <Typography key={i} color='rgb(255 255 255 / 64%)'>
                  {text}
                </Typography>
              ))
            ) : (
              <Typography color='rgb(255 255 255 / 64%)' paragraph gutterBottom>
                {item?.body}
              </Typography>
            )}

            {item?.buttonText && (
              <Button
                variant='contained'
                color='secondary'
                size='large'
                component={RouterLink}
                to={item?.buttonLink}
                endIcon={<ArrowRightIcon />}
              >
                {item?.buttonText}
              </Button>
            )}
          </Box>
        </Container>
      ))}
    </Carousel>
  )
}

export default BannerCarousel
