import React from 'react'
import { createStyles, makeStyles } from '@mui/styles'
import LoadingButton from '@mui/lab/LoadingButton'
import validate from 'validate.js'
import CustomOutlineTextField from './CustomOutlineTextField'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import toast from 'react-hot-toast'
import { useMailChimp } from 'react-use-mailchimp-signup'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
)

const schema = {
  fullName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 120,
    },
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  address: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500,
    },
  },
  state: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 100,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 11,
    },
  },
  // capitalAmount: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     minimum: 1,
  //     maximum: 1000,
  //   },
  // },
  // franchiseeClass: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 300,
  //   },
  // },
  location: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 300,
    },
  },
}
function DistributorForm() {
  const classes = useStyles()
  const { error, loading, status, subscribe, message } = useMailChimp({
    action: `https://freedisity.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`,
  })
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  })

  React.useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState((prevData) => ({
      ...prevData,
      isValid: errors ? false : true,
      errors: errors || {},
    }))
  }, [formState.values])

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false

  const handleChange = (event) => {
    event.persist()

    setFormState((prevData) => ({
      ...prevData,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (formState.isValid) {
      const payload = {
        EMAIL: formState.values?.email,
        MERGE1: formState.values?.fullName,
        MERGE4: formState.values?.phoneNumber,
        MERGE3: formState.values?.address,
        MERGE5: formState.values?.state,
        MERGE6: formState.values?.location,
        MERGE7: 'PARTNER',
      }

      toast.promise(subscribe(payload), {
        loading: 'Sending...',
        success: (data) => {
          // CLEAR FORM
          setFormState((prevData) => ({
            ...prevData,
            isValid: false,
            values: {},
            touched: {},
            errors: {},
          }))
          return `Thank you for connecting with us. \n We are happy to have you.`
        },
        error: (err) => {
          console.log(err)
          return 'Something went wrong while submitting, try again'
        },
      })
    }
    setFormState((prevData) => ({
      ...prevData,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }))
  }

  return (
    <form
      name='partner-form'
      method='post'
      onSubmit={handleSubmit}
      className={classes.root}
    >
      <CustomOutlineTextField
        placeholder='Fullname'
        label='Fullname *'
        variant='outlined'
        size='small'
        name='fullName'
        fullWidth
        helperText={hasError('fullName') ? formState.errors.fullName[0] : null}
        error={hasError('fullName')}
        onChange={handleChange}
        type='text'
        value={formState.values.fullName || ''}
      />
      <CustomOutlineTextField
        placeholder='eg: 08000000000'
        label='Phone Number *'
        variant='outlined'
        size='small'
        name='phoneNumber'
        fullWidth
        helperText={
          hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
        }
        error={hasError('phoneNumber')}
        onChange={handleChange}
        type='text'
        value={formState.values.phoneNumber || ''}
      />
      <CustomOutlineTextField
        placeholder='Email Address'
        label='Email Address *'
        variant='outlined'
        size='small'
        name='email'
        fullWidth
        helperText={hasError('email') ? formState.errors.email[0] : null}
        error={hasError('email')}
        onChange={handleChange}
        type='email'
        value={formState.values.email || ''}
      />
      <CustomOutlineTextField
        placeholder='Address'
        label='Address *'
        variant='outlined'
        size='small'
        name='address'
        fullWidth
        helperText={hasError('address') ? formState.errors.address[0] : null}
        error={hasError('address')}
        onChange={handleChange}
        type='text'
        value={formState.values.address || ''}
      />
      <CustomOutlineTextField
        placeholder='State'
        label='State *'
        variant='outlined'
        size='small'
        name='state'
        fullWidth
        helperText={hasError('state') ? formState.errors.state[0] : null}
        error={hasError('state')}
        onChange={handleChange}
        type='text'
        value={formState.values.state || ''}
      />
      {/* <CustomOutlineTextField
        placeholder='Amount of Working Capital Available'
        label='Amount of Working Capital Available *'
        variant='outlined'
        size='small'
        name='capitalAmount'
        fullWidth
        helperText={
          hasError('capitalAmount') ? formState.errors.capitalAmount[0] : null
        }
        error={hasError('capitalAmount')}
        onChange={handleChange}
        type='text'
        value={formState.values.capitalAmount || ''}
      /> */}
      {/* <CustomOutlineTextField
        placeholder='Class of Franchisee: Microfranchisee/Retailer(MF);Secondary Distribution Partner(SDP);Key Account Distributor(KAD)'
        label='Class of Franchisee *'
        variant='outlined'
        size='small'
        name='franchiseeClass'
        fullWidth
        helperText={
          hasError('franchiseeClass')
            ? formState.errors.franchiseeClass[0]
            : null
        }
        error={hasError('franchiseeClass')}
        onChange={handleChange}
        type='text'
        value={formState.values.franchiseeClass || ''}
      /> */}
      <CustomOutlineTextField
        placeholder=' Location'
        label='Location *'
        variant='outlined'
        size='small'
        name='location'
        fullWidth
        helperText={hasError('location') ? formState.errors.location[0] : null}
        error={hasError('location')}
        onChange={handleChange}
        type='text'
        value={formState.values.location || ''}
      />
      <LoadingButton
        loading={loading}
        size='medium'
        variant='contained'
        type='submit'
        color='secondary'
        endIcon={<ArrowRightIcon />}
      >
        Submit
      </LoadingButton>
    </form>
  )
}

export default DistributorForm
