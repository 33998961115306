import { useEffect } from 'react';
import Box from '@mui/material/Box'
import { AppBarDrawer } from '../components'
import Footer from '../components/Footer'

function PublicLayout({ children, pages, contacts, location }) {
  const query = new URLSearchParams(location.search).get("view");

  const isAppView = Boolean(query === process.env.REACT_APP_VITE_APP_VIEW);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);


  return (
    <div>
      {!isAppView && <AppBarDrawer pages={pages} location={location} />}
      <Box component='main' sx={{ overflowX: 'hidden' }}>
        {children}
      </Box>
      {!isAppView && <Footer contacts={contacts} location={location} />}
    </div>
  )
}

export default PublicLayout
