import React from 'react'
import clsx from 'clsx'
import { createStyles, makeStyles, useTheme } from '@mui/styles'
import { Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { CustomImage, YoutubeEmbed } from '..'

const useStyles = makeStyles((theme) =>
  createStyles({
    show: {
      display: 'none',
    },
    root: {
      width: '100%',
      backgroundColor: theme.palette.primary.light,
      position: 'fixed',
      padding: theme.spacing(2.5),
      bottom: 0,
      zIndex: 1,
    },
    footerContainer: {
      maxWidth: theme.layout.contentWidth,
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(0, 2),
    },
    callButton: {
      backgroundColor: 'white',
      marginLeft: theme.spacing(1),
    },
    footerHeading: {
      fontWeight: 'bolder',
      color: theme.palette.primary.main,
    },
    footerSubheading: {
      color: '#333',
      fontSize: 13,
    },
    title: {
      fontWeight: '900 !important',
      textTransform: 'capitalize',
      lineHeight: 1,
      color: theme.palette.primary.main,
    },
    spacer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    textCenter: {
      textAlign: 'center',
    },
    image: {
      width: '100%',
      height: 600,
      objectFit: 'contain',
    },
  })
)

const ApplyImageAds = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const {
    image,
    title,
    subtitle,
    buttonLink,
    buttonText,
    video,
    alignLeft = false,
  } = props

  return (
    <Grid
      container
      justifyContent='space-between'
      alignItems='center'
      spacing={2}
      direction={alignLeft ? 'row-reverse' : 'row'}
      padding={2}
    >
      <Grid
        xs={12}
        data-aos={'fade-left'}
        sx={{
          textAlign: 'center',
        }}
        item
        sm
      >
        {video ? (
          <YoutubeEmbed embedId={video?.embedId} title={video?.title} />
        ) : (
          <CustomImage src={image} alt='...' className={classes.image} lazy />
        )}
      </Grid>
      <Grid item xs={12} sm data-aos={'fade-right'}>
        <Typography
          className={classes.title}
          variant='h3'
          component='h1'
          gutterBottom
        >
          {title}
        </Typography>
        <Typography paragraph variant='h5'>
          {subtitle}
        </Typography>
        {/* <div className={classes.spacer} /> */}
        {buttonText && (
          <Button
            color='primary'
            variant='contained'
            component={RouterLink}
            to={buttonLink}
            size='large'
            endIcon={<ArrowRightIcon fontSize='small' />}
          >
            {buttonText}
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export default ApplyImageAds
